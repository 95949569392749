// src/controllers/tattoo_controller.js
import { Controller } from "@hotwired/stimulus";
import * as Masonry from "masonry-layout";
import * as imagesLoaded from "imagesloaded";

export default class extends Controller {
  static targets = ["image", "title"];

  connect() {
    this.initializeMasonry();
    this.element[this.identifier] = this;
  }

  show(event) {
    this.imageTarget.src = event.currentTarget.dataset.image;
    this.titleTarget.innerHTML = event.currentTarget.dataset.title;
  }

  initializeMasonry() {
    var grid = this.element.querySelector(".grid");
    var msnry = new Masonry(grid, {
      itemSelector: ".grid-item",
      columnWidth: ".grid-sizer",
      percentPosition: true,
    });

    imagesLoaded(grid).on("progress", function () {
      // layout Masonry after each image loads
     // console.log("masonry layout");
      msnry.layout();
    });


    const source = this;
    msnry.on( 'layoutComplete', function( items ) {
      source.element.style.height = 'auto';
    });

    document
      .querySelectorAll('[data-bs-toggle="pill"]')
      .forEach(function (tabElement) {
        tabElement.addEventListener("shown.bs.tab", function (event) {
          console.log('show')
          msnry.layout();
        });
      });
  }
}
