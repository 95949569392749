import { Controller } from "@hotwired/stimulus";
import Litepicker from "litepicker";
import { formatDate } from "../helpers";

export default class extends Controller {
  connect() {
    this.picker = new Litepicker({
      element: this.element,
      format: "DD/MM/YYYY",
      lang: document.querySelector("html").attributes["lang"],
    });

    this.hiddenElement = document.createElement("input");
    this.hiddenElement.setAttribute("name", this.element.name);
    this.hiddenElement.setAttribute("value", this.element.value);
    this.hiddenElement.setAttribute("type", 'hidden');

    this.element.parentElement.append(this.hiddenElement);

    this.picker.on("selected", (date1, date2) => {
      const date = date1.dateInstance;
      this.hiddenElement.value = formatDate(date, "yyy-mm-dd");
    });
  }
}
