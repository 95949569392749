import Sortable from "stimulus-sortable";
import { patch } from "@rails/request.js";

export default class extends Sortable {
  connect() {
    super.connect();
    console.log("Do what you want here.");

    // The sortable.js instance.
    this.sortable;

    // Your options
    this.options;

    // Your default options
    this.defaultOptions;
  }

  // You can override the `end` method here.
  async end() {
    const data = new FormData();
    this.element.children.forEach((element) => {
      data.append("position[]", element.dataset.sortableItemIdentifier);
    });

    await patch(this.element.dataset.sortableUpdateUrl, { body: data });
  }

  // You can set default options in this getter for all sortable elements.
  get defaultOptions() {
    return {
      animation: 500,
    };
  }
}
