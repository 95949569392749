import Autosave from "stimulus-rails-autosave";

export default class extends Autosave {
  static targets = ["success", "wait"];

  connect() {
    super.connect();
    console.log("Do what you want here.");
  }

  onSubmit() {
    this.waitTarget.style.display = "";
  }

  onSuccess() {
    this.waitTarget.style.display = "none";
    this.successTarget.style.display = "";

    setTimeout(() => {
      this.successTarget.style.display = "none";
    }, 1000);
  }
}
