import PlacesAutocomplete from "stimulus-places-autocomplete";
import { extractDataFromGooglePlace } from "../helpers";

export default class extends PlacesAutocomplete {
  static targets = ["type", "placeName", "locationId"];
  placeChanged() {
    super.placeChanged();

    this.latitudeTarget.value = this.place.geometry.location.lat();
    this.longitudeTarget.value = this.place.geometry.location.lng();
    this.typeTarget.value = this.place.types[0];
    this.placeNameTarget.value = this.place.name;
    this.locationIdTarget.value = "";
  }

  get autocompleteOptions() {
    return {
      componentRestrictions: { country: ["fr", "es", "be", "ch"] },
      fields: ["type", "geometry", "name"],
      types: ['geocode']
    };
  }
}
