import { Controller } from "stimulus";
import "lightgallery.js";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["nextPageLink"];

  initialize() {
    this.observeNextPageLink();
  }

  // private

  async observeNextPageLink() {
    if (!this.hasNextPageLinkTarget) return;

    await nextIntersection(this.nextPageLinkTarget);
    console.log("intersection");
    this.getNextPage();
  }

  async getNextPage() {
    const response = await get(this.nextPageLinkTarget.href); // AJAX request
    this.nextPageLinkTarget.remove();
    const html = await response.text;
    const doc = new DOMParser().parseFromString(html, "text/html");
    const nextPageHTML = doc.querySelector(".master").innerHTML;
    this.element.querySelector(".master").innerHTML += nextPageHTML;

    if (this.element.querySelector(".grid-sizer").length) {
      this.element.querySelector(".grid-sizer").remove();
    }
    if (this.element.querySelector(".loading-indicator").length) {
      this.element.querySelector(".loading-indicator").remove();
    }

    this.refreshMasonry();
    this.refreshLightbox();

    this.observeNextPageLink();
  }

  refreshMasonry() {
    this.element.tattoo.initializeMasonry();
  }

  refreshLightbox() {
    const lighbox = this.application.getControllerForElementAndIdentifier(
      this.element.querySelector('[data-controller="lightbox"]'),
      "lightbox"
    );
    if (!lighbox) return;

    lighbox.connect();
  }
}

const nextIntersection = (targetElement) => {
  return new Promise((resolve) => {
    new IntersectionObserver(([element]) => {
      if (!element.isIntersecting) {
        return;
      } else {
        resolve();
      }
    }).observe(targetElement);
  });
};
