import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  connect() {
    this.element[this.identifier] = this;
  }

  open() {
    var myModal = new Modal(
      document.getElementById("toggleMyModal"),
      {
        keyboard: false,
      }
    );
    myModal.show();
  }
}
