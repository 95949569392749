// src/controllers/tattoo_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    window.onscroll = () => {
      if (document.documentElement.scrollTop > 20) {
        this.element.classList.add("navbar-scroll");
      } else {
        this.element.classList.remove("navbar-scroll");
      }
    };
  }
}
