import { Controller } from "@hotwired/stimulus";
import { clone, append } from "../helpers";

export default class extends Controller {
  static targets = ["day", "open", "close", "row"];

  connect() {
    this.dayTargets.forEach(this.setSuffix);
    this.openTargets.forEach(this.setSuffix);
    this.closeTargets.forEach(this.setSuffix);

    this.openTargets.forEach(this.addChangeListener);
    this.closeTargets.forEach(this.addChangeListener);
  }

  addChangeListener(element) {
    element.addEventListener("change", function (e) {
      e.currentTarget.setAttribute("value", e.currentTarget.value);
    });
  }

  addDay(event) {
    clone(event.currentTarget.parentElement);
  }

  setOption(event) {
    event.currentTarget.querySelectorAll("option").forEach(function (option) {
      if (option.value == option.parentElement.value) {
        option.setAttribute("selected", "selected");
      } else {
        option.removeAttribute("selected");
      }
    });
  }

  removeDay(event) {
    if (this.rowTargets.length == 1) return;

    event.currentTarget.parentElement.remove();
  }

  setSuffix(element) {
    element.setAttribute(
      "name",
      element["name"] + "[][" + element.dataset.openingTarget + "]"
    );
  }
}
