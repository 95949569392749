// src/controllers/tattoo_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    let timer = this.data.get("timer");
    let offset = this.data.get("offset");

    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = this.element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;


    setTimeout(() => {
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }, timer);
  }
}
