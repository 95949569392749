import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["trigger", "modalContent"];
  elementsToRefresh = "";

  connect() {
    this.element[this.identifier] = this;
    this.initializeTriggers();

    this.modal = document.getElementById("ajax-modal");

    this.modal.addEventListener("hidden.bs.modal", (event) => {
      this.setContentLoaderUrl("");
      this.contentLoader().lazyLoad();
      this.modalContentTarget.innerHTML =
        '<i className="fas fa-spinner fa-spin"></i>';

      this.refreshBackgroundElements();
    });

    this.modal.addEventListener("shown.bs.modal", (event) => {
      this.setModalClass();
    });
  }

  setModalClass() {
    const dialog_class_element = this.modal.querySelector(
      "[data-modal-dialog-class]"
    );
    if (dialog_class_element) {
      const dialog_class = dialog_class_element.dataset.modalDialogClass;
      this.modal
        .querySelector(".modal-dialog")
        .setAttribute("class", "modal-dialog " + dialog_class);
    }
  }

  initializeTrigger(event) {
    event.currentTarget.source.setContentLoaderUrl(event.currentTarget["href"]);
    event.currentTarget.source.elementsToRefresh =
      event.currentTarget.getAttribute("data-ajax-modal-refresh-selectors") ||
      "";
  }

  initializeTriggers() {
    this.triggerTargets.forEach((element) => {
      element.setAttribute("data-bs-toggle", "modal");
      element.setAttribute("data-bs-target", "#ajax-modal");

      element.source = this;
      element.removeEventListener("click", this.initializeTrigger);
      element.addEventListener("click", this.initializeTrigger);
    });
  }

  setContentLoaderUrl(url) {
    this.modalContentTarget.setAttribute("data-content-loader-url-value", url);
  }

  async refreshBackgroundElements() {
    if (this.elementsToRefresh == "") return;

    let response = await fetch(document.location.href);
    let html = await response.text();
    const doc = new DOMParser().parseFromString(html, "text/html");

    this.elementsToRefresh.split(",").forEach(function (selector) {
      document.querySelector(selector).outerHTML =
        doc.querySelector(selector).outerHTML;
    });

    this.initializeTriggers();
  }

  contentLoader() {
    return this.modalContentTarget["content-loader"];
  }
}
