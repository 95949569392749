import PlacesAutocomplete from "stimulus-places-autocomplete";
import { extractDataFromGooglePlace } from "../helpers";

export default class extends PlacesAutocomplete {
  placeChanged() {
    super.placeChanged();
    const data = extractDataFromGooglePlace(this.place);

    this.addressTarget.value = this.place.name;
    this.postalCodeTarget.value = data["postal_code"];
    this.cityTarget.value = data["locality"];
    this.latitudeTarget.value = this.place.geometry.location.lat();
    this.longitudeTarget.value = this.place.geometry.location.lng();
  }

  get autocompleteOptions() {
    return {
      types: ["address"],
      fields: ["address_components", "geometry", "name"],
    };
  }
}
