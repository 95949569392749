export function prepend(el, referenceNode) {
  referenceNode.innerHTML = el + referenceNode.innerHTML;
}

export function append(el, referenceNode) {
  referenceNode.innerHTML = referenceNode.innerHTML + el.outerHTML;
}

export function clone(node) {
  node.outerHTML = node.outerHTML + node.outerHTML;

  return node;
}

export function refreshLightbox(element) {
  const lighbox = findController("lightbox", element);
  if (!lighbox) return;

  lighbox.connect();
}

export function refreshMasonry(element) {
  const controller = findController("tattoo", element);
  if (controller) controller.initializeMasonry();
}

export function findController(controller_name, element) {
  return Stimulus.getControllerForElementAndIdentifier(
    element.querySelector('[data-controller*="' + controller_name + '"]'),
    controller_name
  );
}

export function extractDataFromGooglePlace(place) {
  var data = [];

  place.address_components.forEach(function (address_component) {
    data[address_component["types"][0]] = address_component["long_name"];
  });

  return data;
}

export function formatDate(date, format) {
  const map = {
    mm: ("00" + (date.getMonth() + 1)).slice(-2),
    dd: ("00" + date.getDate()).slice(-2),
    yyy: date.getFullYear(),
  };
  return format.replace(/mm|dd|yyy/gi, (matched) => map[matched]);
}
