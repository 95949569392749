import { Controller } from "stimulus";
import Dropzone from "dropzone";

Dropzone.autoDiscover = false;
import { prepend, refreshLightbox, refreshMasonry } from "../helpers";

export default class extends Controller {
  static targets = ["input", "imageList"];

  connect() {
    this.dropZone = createDropZone(this.element);
    this.dropZone.on("success", (file, response) => {
      new ImageUploadController(this, file, response);
    });
  }
}

class ImageUploadController {
  constructor(source, file, response) {
    this.source = source;
    this.file = file;
    this.response = response;

    this.prependResponseToImageList(response);
    this.source.dropZone.removeFile(this.file);
    refreshLightbox(source.element);
    refreshMasonry(source.element);
  }

  prependResponseToImageList(response) {
    if (!this.source.imageListTarget) return;

    prepend(response, this.source.imageListTarget);
  }
}

// Top level...
function createDropZone(element) {
  if (element.querySelector("form")) {
    var dropzone_element = element.querySelector("form");
    var url = null;
  } else {
    var dropzone_element = element;
    var url = element.dataset.dropzoneUrl;
  }

  return new Dropzone(dropzone_element, {
    autoProcessQueue: true,
    uploadMultiple: false,
    parallelUploads: 1,
    url: url,
    paramName: element.querySelector('[type="file"]').name,
    previewsContainer: element.querySelector(".preview-container"),
  });
}
